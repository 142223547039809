

























import { Vue } from 'vue-property-decorator';
import { reportService } from '@/_services';
import { settings }  from '@/variables';
import EventBus from '@/variables'
import { BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
import {  RoomElement, ReportItem, CheckIn } from '@/types';

Vue.use(BootstrapVueIcons);
export default Vue.extend({
  name: "App",
  data() {
    return {
      vorschlagNr: 0,
      tabellenNr: 0,
      clients: [],
      rooms: [] as RoomElement[],
      selectedCheckIn: {},
      today: new Date(),
      eventHub: EventBus,
      selectedRoom: "",
      numberOfDays: 10,
      daysBeforeNow: 3,
      maxReportNumber: 0,
      reports: [] as ReportItem[],
      reportFields: [
        { key: "number", label: "ReportNr", filter: "true"}, 
        { key: "reportDate", label: "Datum", filter: "true"}, 
        { key: "sentTime", label: "Gesendet", filter: "true"}, 
        { key: "actions", label: "Download"}, 
      ],
      fields: [
        { key: "type", label: "Typ", filter: "true" },
        { key: "roomNumber", label: "Zimmer", filter: "true"}, 
        { key: "firstName", label: "Vorname", filter: "true"}, 
        { key: "lastName", label: "Nachname", sortable: "true", },
        { key: "artistName", label: "Künstlername", sortable: "true", },
        { key: "address", label: "Adresse", filter: "true" },
        { key: "zipcode", label: "PLZ", filter: "true" },
        { key: "city", label: "Ort", filter: "true" },
        { key: "birthDay", label: "Geburtstag", filter: "true" },
        { key: "checkInDate", label: "Checkin" },
        { key: "checkInReported", label: "gemeldet" },
        { key: "checkOutDate", label: "Checkout" },
        
      ],
      detailFields: [
        { key: "contactPhone", label: "Telefon" },
        { key: "contactEmail", label: "Email" },
        { key: "healthdata_blut", label: "Blut" },
        { key: "healthdata_abstrich", label: "Abstrich" },
        { key: "healthdata_tbc", label: "TBC Röntgen" },
        { key: "placeOfBirth", label: "Geburtsort", filter: "true" },
        { key: "typeOfStay", label: "Aufenthaltsart", filter: "true" },
        { key: "documentType", label: "Dok.typ" },
        { key: "documentNumber", label: "Passnumer" },
        { key: "documentIssuer", label: "Aussteller" },
        { key: "documentIssueDate", label: "Ausstellungsdatum" },
        { key: "documentValidity", label: "Gültig bis" },
      ],
      checkInData: [] as CheckIn[],
      tempReports: [] as ReportItem[],
      suggestedReport: [] as ReportItem[],
      setting: settings,
      infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
          data: {},
          isNew: false
        }
    };
  },
  created():void {
    this.loadReports();
  },
  methods: {
    asArray(obj: any) {
      let result= [];
      result.push(obj);
      console.log("array: " + JSON.stringify(result));
      return result;
    },
    loadReports() {
      reportService.getReports().then((result: any) => {
        console.log("reports received");
        this.reports = result;
        for (const r of this.reports) {
          if (r.number!=null && r.number>this.maxReportNumber) {
            this.maxReportNumber= r.number;
          }
        }
        this.tabellenNr++;
      });
    },
    openReport(item: any, type: string) {
      const w= window.open(this.setting.printApi+ "/Report?id="+item._key + "&type=" + type, "_blank");
      if (w) {
        w.focus();
      }
    },
    reloadAllData() {
      this.checkInData = [];
      this.suggestedReport= [];
      // this.newInvoices = [];
      this.loadReports();
    },
  },
  components: {
    // EditInvoice,
  }
  
});
